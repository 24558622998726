/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import FirebaseAuthProvider from './firebase/provider'
import AuthInterface from './interface'

export const setAuthProvider = (provider: AuthInterface) => {
    window.auth = provider
}

export const setDefaultAuthProvider = () => setAuthProvider(new FirebaseAuthProvider())

export const removeAuthProvider = () => {
    setDefaultAuthProvider()
}
