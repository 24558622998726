/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFunctionState } from 'vspry-hooks'
import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'

import { Loader } from 'semantic-ui-react'
import { getUserEmailVerificationStatus } from 'api/verification'

import useInterval from 'hooks/useInterval'

// components
import { FlexBox, Icon, Text, Tile, ErrorMessage } from 'vspry-style-components'

const initState = {
    loading: false,
    errorMessage: null,
    redirect: '',
}

export default function VerifyEmail() {
    const location = useLocation()
    const navigate = useNavigate()
    const { user, getUser } = useAuth()
    const { translate } = useLocale()
    const [state, setState] = useFunctionState(initState)
    const { loading, error, errorMessage } = state

    useInterval(async () => {
        const res = await getUserEmailVerificationStatus()
        if (res && res.emailVerified) getUser()
    }, 2000)

    useEffect(() => {
        if (!user) navigate('/')
    }, [])

    useEffect(() => window.auth.sendVerificationEmail(encodeURIComponent(`${location.pathname}${location.search}`)), [location])

    const sendEmail = async () => {
        setState({ loading: true })

        try {
            await window.auth.sendVerificationEmail(encodeURIComponent(`${location.pathname}${location.search}`))
            setState({ loading: false })
        } catch (e) {
            setState({ loading: false, error: true, errorMessage: e.message })
        }
    }

    return (
        <Tile>
            <FlexBox $column align='center' justify='center' style={{ padding: '20px' }}>
                {error && <ErrorMessage error={errorMessage} />}
                <Icon name='send' size='large' />
                <Text size='xSmall' margin='large'>
                    {translate('verifyEmail.paragraph', { email: user.email })}
                </Text>
                {!loading && (
                    <Text id='send-email-button' $bold size='small' onClick={sendEmail}>
                        {translate('buttons.emailNotReceived')}
                    </Text>
                )}
                {loading && (
                    <Loader inline active>
                        {translate('placeholders.resendEmailVerification')}
                    </Loader>
                )}
            </FlexBox>
        </Tile>
    )
}
