/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, FlexBox, Text } from 'vspry-style-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// Components
import Logo from 'components/Logo'
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import VspryDivider from 'components/style-elements/VspryDivider'

// utils
import { useAppConfig } from 'context/appConfigContext'
import { useLocale } from 'context/localeContext'
import { useAuth } from 'context/authContext'

const StyledDiv = styled.div`
    border: 2px dotted ${(props) => props.theme.primaryOBJ}60;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 18px;
`

/**
 * This is the main landing page of app.vspry.com
 * Vspry Australia Pty Ltd (c) 2019
 */

export default function LandingPage() {
    const { translate } = useLocale()
    const { user } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const { appConfig } = useAppConfig()
    const [searchParams] = useSearchParams()
    const [redirect, setRedirect] = useState('/home')

    useEffect(() => {
        const redirectP = searchParams.get('redirect')
        if (redirectP) setRedirect(decodeURIComponent(redirectP))

        if (user) navigate(redirectP ? decodeURIComponent(redirectP) : redirect)
    }, [user, location])

    useEffect(() => {
        const redirectP = searchParams.get('redirect')
        if (!appConfig.consumers_enabled && appConfig.merchants_enabled)
            navigate(`/signin?type=email&redirect=${encodeURIComponent(redirectP ?? redirect)}&tenant=merchant`)
    }, [appConfig])

    const showSignup =
        (redirect.includes('referral') || !appConfig.referral_signup_only_enabled) && (appConfig.consumers_enabled || appConfig.external_register)

    return (
        <PublicPageContainer>
            <Logo />
            <FlexBox $column $align gap='small' $fitted>
                <FlexBox $column gap='small' width='50%'>
                    <Button id='email-sign-in' onClick={() => navigate(`/signin?redirect=${encodeURIComponent(redirect)}`)}>
                        {translate('buttons.signInEmail')}
                    </Button>
                    {appConfig.phone_sign_in_enabled && (
                        <Button id='phone-sign-in' onClick={() => navigate(`/signin?type=phone&redirect=${encodeURIComponent(redirect)}`)}>
                            {translate('buttons.signInPhone')}
                        </Button>
                    )}
                    {window.configuration['EXT_SSO_CLIENT_ID'] && window.configuration['EXT_SSO_CLIENT_SECRET'] && (
                        <Button id='ext-sign-in' onClick={() => navigate(`/signin?type=ext&redirect=${encodeURIComponent(redirect)}`)}>
                            {translate('buttons.signInExt')}
                        </Button>
                    )}
                </FlexBox>
                <VspryDivider>{translate('pages.landingPage.dontHaveAccount')}</VspryDivider>
                {showSignup ? (
                    <Button
                        id='new-user-signup'
                        color='highlight'
                        onClick={() => navigate(`/signup?redirect=${encodeURIComponent(redirect)}`)}
                        width='50%'
                    >
                        {translate('pages.landingPage.openConsumerAccount')}
                    </Button>
                ) : (
                    <Text size='small' margin='no'>
                        {translate('pages.landingPage.askPractitioner')}
                    </Text>
                )}
            </FlexBox>
            <FlexBox $column gap='small' width='50%'>
                {appConfig.merchants_enabled && (
                    <Button
                        id='merchant-sign-in'
                        onClick={() => navigate(`/signin?type=email&redirect=${encodeURIComponent(redirect)}&tenant=merchant`)}
                        color='disabledButtonBG'
                    >
                        {translate('buttons.merchantSignin')}
                    </Button>
                )}
            </FlexBox>
        </PublicPageContainer>
    )
}
