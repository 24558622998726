/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { FlexBox, Text } from 'vspry-style-components'

import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'

import PageContainer from 'components/style-elements/containers/PageContainer'
import VerifyEmail from 'components/verification/VerifyEmail'
import VerifyPhone from 'components/verification/VerifyPhone'
import { isCase } from 'api/marketplace'

export default function VerificationPage() {
    const { translate } = useLocale()
    const { user, currentProduct } = useAuth()

    return (
        <PageContainer
            title={!isCase(currentProduct) ? currentProduct?.product ?? translate('navigation.productHome') : translate('navigation.productHome')}
        >
            <FlexBox $column margin='large'>
                <Text $bold>{translate('pages.verification.title')}</Text>
                <Text size='small'>{translate('pages.verification.instructions')}</Text>
                {!user?.emailVerified && <VerifyEmail />}
                {user?.emailVerified && !user.phoneVerified && user.phone && <VerifyPhone />}
            </FlexBox>
        </PageContainer>
    )
}
