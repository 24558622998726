/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { createContext, ReactNode, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { getUserUnreadMessages, setMessageRead } from 'api/common'
import useInterval from 'hooks/useInterval'
import { useFunctionState } from 'vspry-hooks'
import { useAuth } from './authContext'

type MessagingProviderState = {
    unread: number
    canRefresh: boolean
    refreshQueued: boolean
    loading: boolean
}

const init: MessagingProviderState = {
    unread: 0,
    canRefresh: true,
    refreshQueued: false,
    loading: false,
}

export const useMessagingProvider = (getUnreadMessages: () => Promise<number>, setRead?: (id: string, read: boolean) => Promise<unknown>) => {
    const [{ unread, canRefresh, refreshQueued, loading }, setState] = useFunctionState(init)
    const { user } = useAuth()

    const fetchUnread = async () => {
        setState({ loading: true })
        const res = await getUnreadMessages()
        setState({ unread: res, loading: false })
    }
    useEffect(() => {
        if (user) fetchUnread()
    }, [user])

    const refresh = () => {
        if (canRefresh && user) {
            setState({ canRefresh: false, refreshQueued: false })
            fetchUnread()
            return setTimeout(() => setState({ canRefresh: true }), 10000)
        }
        return setState({ refreshQueued: true })
    }

    const setMessageReadAndRefresh = async (id: string) => {
        if (setRead) await setRead(id, true)
        return fetchUnread()
    }

    useEffect(() => {
        if (user && canRefresh && refreshQueued) refresh()
    }, [canRefresh, user])

    useInterval(async () => refresh(), 30000)

    return { loading, refresh, setMessageReadAndRefresh, unread }
}

type MessagingContext = ReturnType<typeof useMessagingProvider>
const context = createContext({} as MessagingContext)
export const useMessaging = () => useContext(context)

export function MessagingProvider({ children }: { children: ReactNode }) {
    const providerValue = useMessagingProvider(getUserUnreadMessages, setMessageRead)
    return <context.Provider value={providerValue}>{children}</context.Provider>
}

MessagingProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
