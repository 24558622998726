/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { createContext, ReactNode, useContext } from 'react'
import PropTypes from 'prop-types'

import { getMerchantUnreadClaims } from 'api/merchant'
import { useMessagingProvider } from './messageContext'

type ClaimsContext = ReturnType<typeof useMessagingProvider>
const context = createContext({} as ClaimsContext)
export const useClaims = () => useContext(context)

export function ClaimsProvider({ children }: { children: ReactNode }) {
    const providerValue = useMessagingProvider(getMerchantUnreadClaims)
    return <context.Provider value={providerValue}>{children}</context.Provider>
}

ClaimsProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
