/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { removeAuthProvider } from '..'
import AuthInterface from '../interface'

export default class SSOAuthProvider implements AuthInterface {
    #IDToken!: string
    #logOut: () => void

    constructor(logOut: () => void) {
        this.#logOut = logOut
    }

    async getIDToken(): Promise<string> {
        return this.#IDToken
    }

    setIDToken(token: string): void {
        this.#IDToken = token
    }

    async logOut(): Promise<void> {
        this.#logOut()
        return removeAuthProvider()
    }
}
