/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'
import { Loader } from 'semantic-ui-react'
import { Button, Text, FlexBox, TextInput } from 'vspry-style-components'
import { useFormValidation, useFunctionState } from 'vspry-hooks'

// components
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'

import Logo from 'components/Logo'

// utilities
import { useLocale } from 'context/localeContext'
import { verifyUserEmail } from 'api/verification'
import HeadingText from 'components/HeadingText'
import { FirebaseError } from 'firebase/app'

type State = {
    loading: boolean

    newPassword: string
    confirmNewPassword: string
    phoneVerified: boolean
    verificationCode: string
    oobCode: string | null
    mode: string | null

    email: string
    emailVerifyError: string | null
    emailVerifySuccess: boolean | null

    passwordCodeValid: boolean | null
    passwordResetSuccessful: boolean | null
    validated: boolean
    redirect: string
}
const initState: State = {
    loading: false,
    newPassword: '',
    confirmNewPassword: '',
    phoneVerified: false,
    verificationCode: '',
    oobCode: null,
    mode: null,
    email: '',
    emailVerifyError: null,
    emailVerifySuccess: null,

    passwordCodeValid: null,
    passwordResetSuccessful: null,
    validated: false,
    redirect: '',
}

export default function Redirect() {
    const { translate } = useLocale()
    const [state, setState] = useFunctionState(initState)
    const location = useLocation()
    const navigate = useNavigate()
    const { onFieldValidate } = useFormValidation((v) => setState({ validated: v }))

    const {
        email,
        oobCode,
        mode,
        newPassword,
        confirmNewPassword,
        loading,
        passwordCodeValid,
        phoneVerified,
        passwordResetSuccessful,
        emailVerifyError,
        emailVerifySuccess,
        validated,
    } = state

    // applies a google oob code for email verification
    const applyActionCode = async (code: string, userEmail: string) => {
        try {
            await window.auth.verifyEmailCode(code)
        } catch (error) {
            // Link is invalid, expired or has already been used
            console.error(error)
            if (error instanceof FirebaseError && error.message.includes('auth/invalid-action-code'))
                return setState({ emailVerifyError: 'Invalid code, please try again', emailVerifySuccess: false })
            if (error instanceof Error) return setState({ emailVerifyError: error.message, emailVerifySuccess: false })
            return setState({ emailVerifyError: 'Unknown error occurred', emailVerifySuccess: false })
        }
        setState({ emailVerifySuccess: true })
        return verifyUserEmail({ email: userEmail })
    }

    useEffect(() => {
        const urlParams = location.search.slice(1)
        const newParams = qs.parse(urlParams)

        let continueUrlParams = null
        if (newParams?.['continueUrl'] && typeof newParams['continueUrl'] === 'string') {
            const continueUrl = new URL(newParams['continueUrl'])
            continueUrlParams = qs.parse(continueUrl.search.slice(1))
        }

        if (newParams?.['oobCode'] && typeof newParams['oobCode'] === 'string') setState({ oobCode: newParams['oobCode'] })
        if (newParams?.['mode'] && typeof newParams['mode'] === 'string') setState({ mode: newParams['mode'] })
        if (continueUrlParams?.['email'] && typeof continueUrlParams['email'] === 'string') setState({ email: continueUrlParams['email'] })
        if (continueUrlParams?.['redirect'] && typeof continueUrlParams['redirect'] === 'string')
            setState({ redirect: continueUrlParams['redirect'] })

        if (newParams?.['tenantId'] && typeof newParams['tenantId'] === 'string') window.auth.setTenantByID(newParams['tenantId'])
        if (mode === 'verifyEmail' && oobCode && continueUrlParams?.['email'] && typeof continueUrlParams['email'] === 'string')
            applyActionCode(oobCode, continueUrlParams['email'])
    }, [])

    // redirects
    const redirectSignIn = () => navigate(`/signin${state.redirect ? `?redirect=${encodeURIComponent(state.redirect)}` : ''}`)
    const redirectPasswordResetRequest = () => navigate('/password-reset-request')

    // requests a password reset
    const resetPassword = async () => {
        setState({ loading: true })
        if (!oobCode) return
        try {
            await window.auth.verifyPasswordResetCode(oobCode)
            setState({ passwordCodeValid: true })
        } catch (error) {
            console.error(error)
            setState({ passwordCodeValid: false, loading: false })
            return
        }

        try {
            await window.auth.confirmPasswordReset(oobCode, newPassword)
            setState({ passwordResetSuccessful: true, loading: false })
        } catch (error) {
            console.error(error)
            setState({ passwordResetSuccessful: false, loading: false })
        }
    }

    // renders the password reset result
    const renderResetPasswordResult = () => {
        if (passwordCodeValid === null) return null
        if (passwordCodeValid) {
            if (passwordResetSuccessful === null) {
                return (
                    <>
                        <HeadingText>{translate(`redirect.passwordReset.title`)}</HeadingText>
                        <Text size='xSmall'>{translate('placeholders.resettingPassword')}</Text>
                        <Loader id='email-loader' inline active inverted />
                    </>
                )
            }

            if (passwordResetSuccessful) {
                return (
                    <>
                        <HeadingText>{translate(`redirect.passwordReset.success`)}</HeadingText>
                        <Text size='xSmall'>{translate(`redirect.passwordReset.successInfo`)}</Text>
                        <Button id='reset-success' $fitted onClick={() => navigate('/home')}>
                            {translate(`buttons.signIn`)}
                        </Button>
                    </>
                )
            }
            return (
                <>
                    <HeadingText>{translate(`redirect.passwordReset.failed`)}</HeadingText>
                    <Text size='xSmall'>{translate(`errors.passwordResetLink`)}</Text>
                    <Button id='reset-failure' $fitted onClick={redirectPasswordResetRequest}>
                        {translate(`buttons.retry`)}
                    </Button>
                </>
            )
        }

        return (
            <>
                <HeadingText>{translate(`redirect.passwordReset.failed`)}</HeadingText>
                <Text size='xSmall'>{translate(`errors.passwordResetFailed`)}</Text>
                <Button id='reset-failure' $fitted onClick={redirectPasswordResetRequest}>
                    {translate(`buttons.retry`)}
                </Button>
            </>
        )
    }

    // renders the password reset form
    const renderResetPasswordForm = () => {
        if (passwordCodeValid !== null) return renderResetPasswordResult()
        const checkPassword = (confirm: string) => confirm === newPassword && newPassword !== ''

        return (
            (phoneVerified || !email) && (
                <FlexBox $column gap='small' $align>
                    <HeadingText>{translate(`redirect.passwordReset.title`)}</HeadingText>
                    <Text size='xSmall' margin='no'>
                        {translate(`redirect.passwordReset.info`)}
                    </Text>
                    <TextInput
                        id='password-input'
                        label={translate('inputs.password')}
                        type='password'
                        value={newPassword}
                        handleChange={(input) => setState({ newPassword: input })}
                        validator={(v) => v.length >= 6}
                        setValidated={onFieldValidate('password')}
                    />
                    <TextInput
                        id='password-confirm-input'
                        label={translate('inputs.passwordConfirm')}
                        type='password'
                        value={confirmNewPassword}
                        handleChange={(input) => setState({ confirmNewPassword: input })}
                        validator={(v) => v === newPassword}
                        validationTriggers={[newPassword]}
                        setValidated={onFieldValidate('passwordConfirm')}
                    />
                    <Button
                        $fitted
                        id='password-reset-button'
                        onClick={resetPassword}
                        loading={loading}
                        disabled={!validated || !checkPassword(confirmNewPassword)}
                    >
                        {translate('inputs.resetPassword.updatePassword')}
                    </Button>
                </FlexBox>
            )
        )
    }

    // renders the verify email form
    const renderVerifyEmail = () => {
        if (emailVerifySuccess === null) {
            return (
                <>
                    <Text size='small'>
                        <Loader active inline inverted />
                    </Text>
                    <Text $bold size='small' margin='large'>
                        {translate('placeholders.verifyingEmail')}
                    </Text>
                </>
            )
        }
        if (emailVerifySuccess) {
            return (
                <>
                    <Text size='small'>{translate(`redirect.emailVerify.title`)}</Text>
                    <Text size='small'>{translate(`redirect.emailVerify.info`)}</Text>
                    <Button id='redirect-sign-in' $fitted onClick={redirectSignIn}>
                        {translate('buttons.signIn')}
                    </Button>
                </>
            )
        }
        return (
            <Text size='small'>
                {translate(`redirect.verifyEmail.failed`)}
                <br />
                {emailVerifyError}
            </Text>
        )
    }

    return (
        <PublicPageContainer>
            <Logo />
            <FlexBox $column $align $fitted>
                {mode === 'resetPassword' && renderResetPasswordForm()}
                {mode === 'verifyEmail' && renderVerifyEmail()}
            </FlexBox>
        </PublicPageContainer>
    )
}
