/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */

import { auth } from 'api/firebase'
import { recordPhoneUsage } from 'api/consumer'
import {
    applyActionCode,
    browserLocalPersistence,
    confirmPasswordReset,
    onAuthStateChanged,
    PhoneAuthProvider,
    RecaptchaVerifier,
    sendEmailVerification,
    sendPasswordResetEmail,
    setPersistence,
    signInWithCredential,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    useDeviceLanguage,
    verifyPasswordResetCode,
} from 'firebase/auth'
import { removeAuthProvider } from '..'
import AuthInterface from '../interface'

export default class FirebaseAuthProvider extends AuthInterface {
    constructor() {
        super()
        setPersistence(auth, browserLocalPersistence)
        useDeviceLanguage(auth)
    }

    async getIDToken(): Promise<string | null> {
        return auth.currentUser ? auth.currentUser.getIdToken() : null
    }

    setIDToken(): void {
        // eslint-disable-next-line no-useless-return
        return
    }

    onChangeSubscriber(callback: (isLogin: boolean) => void): () => void {
        return onAuthStateChanged(auth, (u) => callback(!!u))
    }

    setTenant(tenant: 'consumer' | 'merchant'): void {
        switch (tenant) {
            case 'merchant':
                auth.tenantId = window.configuration['FIREBASE_AUTH_MERCHANT_TENANT_ID']
                break
            default:
                auth.tenantId = window.configuration['FIREBASE_AUTH_CONSUMER_TENANT_ID'] || null
                break
        }
    }

    setTenantByID(tenantID: string): void {
        auth.tenantId = tenantID
    }

    createRecaptcha(): RecaptchaVerifier {
        return new RecaptchaVerifier('recaptcha', { size: 'invisible' }, auth)
    }

    clearRecaptcha(recaptcha: RecaptchaVerifier): void {
        try {
            recaptcha.clear()
        } catch (e) {
            console.warn(e)
        }
        const el = document?.getElementById('recaptcha-wrapper')
        if (el) el.innerHTML = `<div id='recaptcha' />`
    }

    async sendVerificationEmail(redirect?: string): Promise<void> {
        if (!auth.currentUser) throw new Error('User is not logged in')
        const actionCodeSettings = {
            url: `https://${window.location.hostname}?email=${auth.currentUser.email}${redirect ? `&redirect=${redirect}` : ''}`,
        }
        return sendEmailVerification(auth.currentUser, actionCodeSettings)
    }

    async sendPasswordResetEmail(email: string): Promise<void> {
        return sendPasswordResetEmail(auth, email)
    }

    async sendPhoneCode(phone: string, recaptcha?: RecaptchaVerifier): Promise<string> {
        await recordPhoneUsage({ phone })
        return new PhoneAuthProvider(auth).verifyPhoneNumber(phone, recaptcha || this.createRecaptcha())
    }

    async loginWithPhoneCode(code: string | number, validator: string): Promise<void> {
        const user = await signInWithCredential(auth, PhoneAuthProvider.credential(validator, String(code)))
        if (user) this.setMonitoringUser({ id: user.user.uid, email: user.user?.email ?? undefined, username: user.user?.displayName ?? undefined })
    }

    async loginWithEmailAndPassword(email: string, password: string): Promise<void> {
        const user = await signInWithEmailAndPassword(auth, email, password)
        if (user) this.setMonitoringUser({ id: user.user.uid, email: user.user?.email ?? undefined, username: user.user?.displayName ?? undefined })
    }

    async resetPassword(newPassword: string): Promise<void> {
        const u = auth.currentUser
        if (!u) throw new Error('User is not logged in')
        return updatePassword(u, newPassword)
    }

    async verifyPasswordResetCode(code: string | number): Promise<void> {
        await verifyPasswordResetCode(auth, String(code))
    }

    async confirmPasswordReset(code: string | number, password: string): Promise<void> {
        return confirmPasswordReset(auth, String(code), password)
    }

    async verifyEmailCode(code: string | number): Promise<void> {
        return applyActionCode(auth, String(code))
    }

    async logOut(): Promise<void> {
        signOut(auth)
        this.setMonitoringUser(null)
        return removeAuthProvider()
    }
}
