/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { createGlobalStyle } from 'styled-components'

import theme from './theme'

const GlobalStyle = createGlobalStyle`
body {
	font-family: ${theme.font};
	height: initial !important;
}

button {
	font-family: ${theme.font};
}

.ui.icon.input > input {
	padding-right: 0 !important;
}

.ui.icon.button > .icon,
.ui.icon.buttons .button > .icon {
	margin: auto !important;
}

// Additional
.ui.button.standard {
	font-size: 16px;
	width: 240px;
}

.ui.button.standard-small {
	font-size: 12px;
	width: 200px;
}

.ui.button.social {
	font-size: 16px;
	border-radius: 100px;
}

.ui.buttons {
	&.small {
		.ui.button:not(.no-styling) {
			height: 36px;
		}
	}
	.ui.button:not(.no-styling) {
		background: rgba(255, 255, 255, 0.5);
		&.active {
			background: #fff;
		}
	}
}

.ui.buttons .button:first-child {
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.ui.buttons .button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
}

.ui.button:not(.no-styling) {
	font-size: 16px;
	border-radius: 100px;
	background: #fff;
	color: #000;
	width: 240px; // Additional
	-webkit-appearance: none;
	&.link {
		background: transparent;
		color: #fff;
	}
	&.size-200 {
		width: 200px !important;
	}
	&.size-40 {
		width: auto !important;
	}
}

// identity provider
.smileui-container {
	background-color: rgba(0, 0, 0, 0.5);
}

.message-button {
	border: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
	color: ${theme.buttonText};
	background: ${theme.buttonBG};
	display: block;
	width: 100%;
	margin: 0px auto;

    :hover {
        background: ${theme.buttonBG}c8;
		color: ${theme.buttonText};
        cursor: pointer;
    }
}

.disabled-message-button {
	pointer-events: none;
	background: #0004;
}

.tox-notifications-container {
	display: none !important;
}

.trix-editor {
	text-align: left;
	min-height: 200px;
	max-height: 300px;
	overflow-y: auto;
}
`

export default GlobalStyle
