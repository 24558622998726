/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Address } from 'utils/formatters'
import { OfferActivation } from './common'
import { plutoFormMutation, plutoMutation, plutoQuery, stringify } from './gqlInterface'
import { MutationResponse, mutationResponseString, PaginatedResponse, Pagination, paginationString } from './types'

// queries
export type BankAccount = {
    id: string
    bsb: string
    accountNo: string
    accountName: string
}
export type Mandate = {
    id: string
    depositAmount: number
    depositFrequency: string
    depositNextDate: string
    bankAccount: BankAccount
}
export const getHoldingMandate = async (mandateID: string) => plutoQuery<Mandate>`
    mandate(mandateID: ${mandateID}) {
        id
        depositAmount
        depositFrequency
        depositNextDate
        bankAccount {
        id
        bsb
        accountNo
        accountName
        }
    }
`

type GetUserBankAccountsResponse = { bankAccounts: BankAccount[] }
export const getUserBankAccounts = async () => {
    const res = await plutoQuery<GetUserBankAccountsResponse>`
        contact {
            bankAccounts {
                id
                bsb
                accountNo
                accountName
            }
        }
    `
    return res?.bankAccounts ?? null
}

type GetHoldingDepositAccountResponse = { accounts: [{ holdings: [{ depositAccount: Omit<BankAccount, 'id'> }] }] }
export const getHoldingDepositAccount = async (accountID: string, holdingID: string) => {
    const res = await plutoQuery<GetHoldingDepositAccountResponse>`
        contact {
            accounts(id:"${accountID}") {
                holdings(id:"${holdingID}") {
                    depositAccount {
                        bsb
                        accountNo
                        accountName
                    }
                }
            }
        }`
    return res?.accounts[0]?.holdings[0]?.depositAccount ?? null
}

type GetHoldingMandatesResponse = {
    accounts: [{ holdings: [{ mandates: Mandate[] }] }]
}
export const getHoldingMandates = async (accountID: string, holdingID: string) => {
    const res = await plutoQuery<GetHoldingMandatesResponse>`
        contact {
            accounts(id:"${accountID}") {
                holdings(id:"${holdingID}") {
                    mandates {
                        id
                        depositAmount
                        depositFrequency
                        depositNextDate
                        bankAccount {
                            id
                            bsb
                            accountNo
                            accountName
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.holdings[0]?.mandates ?? null
}

type Application = {
    id: string
    status: string
    amount: string
}
export const getHoldingApplication = async (holdingID: string) => plutoQuery<Application>`
    holdingApplication(holdingID:"${holdingID}") {
        id
        status
        amount
    }
`

type GetHoldingLoanDetailsResponse = {
    accounts: [{ holdings: [{ daysInArrears: number; nextRepaymentDueDate?: string; repayment: number }] }]
}
export const getHoldingLoanDetails = async (accountID: string, holdingID: string) => {
    const res = await plutoQuery<GetHoldingLoanDetailsResponse>`
        contact {
            accounts(id:"${accountID}") {
                holdings(id:"${holdingID}") {
                    daysInArrears
                    nextRepaymentDueDate
                    repayment
                }
            }
        }
    `
    return res?.accounts[0]?.holdings[0] ?? null
}

type PolicyDetails = {
    id: string
    planName: string
    planDescription: string
    accountName: string
    principalName: string
    principalID: string
    cardNumber: string
    benefitLimits: {
        availment: string
        amount: string
        type: string
    }[]
    publicHealthStatus?: string
    accommodation: string
    hospitalAccess: string
    effectivityDate: string
    expiryDate: string
    dependents: {
        id: string
        name: string
        cardNumber: string
        status: string
        relationship: string
    }[]
}
export const getPolicyDetails = async () => plutoQuery<PolicyDetails>`
    policyDetails {
        planName
        planDescription
        accountName
        principalName
        principalID
        cardNumber
        benefitLimits {
            availment
            amount
            type
        }
        publicHealthStatus
        accommodation
        hospitalAccess
        effectivityDate
        expiryDate
        dependents {
            id
            name
            cardNumber
            status
            relationship
        }
    }
`

export type Payout = { id: string; value: string; expiryDate: string }
type GetHoldingPayoutResponse = {
    accounts: [{ holdings: [{ payouts: [Payout] }] }]
}
export const getHoldingPayout = async (accountID: string, holdingID: string, payoutID: string) => {
    const res = await plutoQuery<GetHoldingPayoutResponse>`
        contact {
            accounts(id:"${accountID}") {
                holdings(id:"${holdingID}") {
                    payouts(id:"${payoutID}") {
                        id
                        value
                        expiryDate
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.holdings[0]?.payouts[0] ?? null
}

export type Request = {
    id: string
    ref: string
    status: string
    amount: string
    lastUpdated: string
    failureReason?: string
}
type GetHoldingPaymentRequestsResponse = {
    accounts: [{ holdings: [{ requests: PaginatedResponse<Request> }] }]
}
export const getHoldingPaymentRequests = async (accountID: string, holdingID: string, pagination: Pagination) => {
    const res = await plutoQuery<GetHoldingPaymentRequestsResponse>`
        contact {
            accounts(id:"${accountID}") {
                holdings(id:"${holdingID}") {
                    requests(pagination: ${pagination}) {
                        set {
                            id
                            ref
                            status
                            amount
                            lastUpdated
                            failureReason
                        }
                        pagination {
                            nextPage
                            prevPage
                            total
                            pageNavigation {
                                page
                                items
                                cursor
                            }
                        }
                    }
                }
            }
        }`
    return res?.accounts[0]?.holdings[0]?.requests ?? null
}

type PolicyActivity = {
    id: string
    date: string
    type: string
    subject: string
    location?: string
    claimNumber?: string
    coverage?: string
    batchID?: string
    treatingDoctor?: string
    treatment?: string
    form?: string
    hasDownload?: boolean
}
export const getPolicyActivities = async () => plutoQuery<PolicyActivity[]>`
    activity {
        id
        date
        type
        subject
        location
        claimNumber
        coverage
        batchID
        treatingDoctor
        treatment
        form
        hasDownload
    }
`

type PolicyLOAFile = {
    fileString: string
    fileName: string
    fileExtension: string
}
export const getPolicyLOAFile = async (id: string) => plutoQuery<PolicyLOAFile>`
    getLOAFile(id: ${id}) {
        fileString
        fileName
        fileExtension
    }
`

type GetHoldingOfferActivationsResponse = {
    accounts: [{ holdings: [{ offerActivations: PaginatedResponse<OfferActivation> }] }]
}
export const getHoldingOfferActivations = async (accountID: string, holdingID: string, pagination: Pagination, statuses: string[], sort: string) => {
    const res = await plutoQuery<GetHoldingOfferActivationsResponse>`
        contact {
            accounts(id:"${accountID}") {
                holdings(id:"${holdingID}") {
                    offerActivations(pagination: ${pagination}, status: ${statuses}, sort: ${sort}) {
                        set {
                            id
                            status
                            activatedDate
                            consumedDate
                            offer {
                                title
                                description
                                activationLastDate
                                imageURL
                            }
                        }
                        ${paginationString}
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.holdings[0]?.offerActivations ?? null
}

type GetComparatorProductsInput = {
    contactID: string
    amount: number
    repayment: number
    term?: string
    addressStartDate: string
    employmentStartDate: string
    salary: number
    occupation: string
    employer?: string
}
type GetComparatorProductsResponse = {
    id: string
    name: string
    issuer: string
    fees: {
        name: string
        type: 'upfront' | 'recurring'
        amount: number
    }[]
    interest: {
        annualizedRate: number
        freq: 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'semiAnnually' | 'annually'
    }
    currency: string
    features: string[]
    term: number
}
export const getComparatorProducts = async (input: GetComparatorProductsInput) => plutoQuery<GetComparatorProductsResponse>`
    loanApplicationComparator(input: ${input}) {
        id
        name
        issuer
        fees {
            name
            type
            amount
        }
        interest {
            annualizedRate
            freq
        }
        currency
        features
        term
    }
`

// mutations
type AddHouseholdMemberInput = {
    accountID: string
    firstName: string
    lastName: string
    email: string
    phone?: string
    role: string
    relationship: string
}
export const addHouseholdMember = async (input: AddHouseholdMemberInput) => plutoMutation`
    addHouseholdMember(input: ${input}) ${mutationResponseString}
`

type EditHouseholdMemberInput = {
    accountID: string
    contactID: string
    role: string
    relationship: string
}
export const editHouseholdMember = async (input: EditHouseholdMemberInput) => plutoMutation`
    editHouseholdMember(input: ${input}) ${mutationResponseString}
`

type UnlinkHouseholdMemberInput = {
    accountID: string
    contactID: string
}
export const unlinkHouseholdMember = async (input: UnlinkHouseholdMemberInput) => plutoMutation`
    unlinkHouseholdMember(input: ${input}) ${mutationResponseString}
`

type RecordPhoneUsageInput = {
    phone: string
}
export const recordPhoneUsage = async (input: RecordPhoneUsageInput) => plutoMutation`
    recordPhoneUsage(input: ${input}) ${mutationResponseString}
`

type ActivateOfferInput = {
    accountID: string
    offerID: string
    holdingID?: string
}
export const activateOffer = async (input: ActivateOfferInput) => plutoMutation`
    activateOffer(input: ${input}) ${mutationResponseString}
`

type RedeemRewardInput = {
    accountID: string
    goalID: string
    holdingID: string
}
type RedeemRewardResponse = {
    success: boolean
    message: boolean
    receipt?: {
        merchant: string
        goal: string
        value: number
        date: string
        contact: string
    }
}
export const redeemReward = async (input: RedeemRewardInput) => plutoMutation<RedeemRewardResponse>`
    redeemReward(input: ${input}) { success message receipt { merchant goal value date contact } }
`

type ApplyForLoanInput = {
    accountID: string
    contactID: string
    term: number
    currency: string
    amount: number
    purpose: string
    nickname?: string
    acceptedTerms: string[]
    device: string
    depChildren: string
    gender: string
    maritalStatus: string
    education: string
    citizenship: string
    resStatus: string
    addressStartDate: string
    employer?: string
    otherEmployer?: string
    employmentStartDate: string
    employmentBasis: string
    occupation: string
    salary: number
    product?: string
    employmentFiles: File[]
    identityFiles: File[]
}
export const applyForLoan = async ({ employmentFiles, identityFiles, ...rest }: ApplyForLoanInput) => {
    const argString = (Object.keys(rest) as (keyof typeof rest)[]).map((k) => `${k}:${stringify(rest[k], true)}`).join(',')
    const query = `mutation($employmentFiles: [Upload!], $identityFiles: [Upload!]) {
        applyForLoan(input: { employmentFiles: $employmentFiles, identityFiles: $identityFiles, ${argString} }) ${mutationResponseString}
    }`
    return plutoFormMutation(query, { employmentFiles, identityFiles })
}

type ApplyForPayoutInput = {
    holdingID: string
}
type ApplyForLoanResponse = MutationResponse & { id?: string }
export const applyForPayout = async (input: ApplyForPayoutInput) =>
    plutoMutation<ApplyForLoanResponse>`calculatePayout(input: ${input}) { success message id }`

type CreateUserBankAccountInput = {
    processor?: {
        referenceId: string
        contactId: string
        agreementId?: string
    }
    accountDetails?: {
        id?: string
        bsb: string
        accountNo: string
        accountName: string
    }
}
type CreateUserBankAccountResponse = MutationResponse & { bankAccount?: BankAccount }
export const createUserBankAccount = async (input: CreateUserBankAccountInput) =>
    plutoMutation<CreateUserBankAccountResponse>`createBankAccount(input: ${input}) { success message bankAccount { id bsb accountNo accountName } }`

type DeleteUserBankAccountInput = {
    id: string
}
export const deleteUserBankAccount = async (input: DeleteUserBankAccountInput) =>
    plutoMutation`removeBankAccount(input: ${input}) ${mutationResponseString}`

type CreateHoldingMandateInput = {
    holding: string
    contact: string
    depositAmount: number
    depositFrequency: string
    depositStartDate: string
    bankAccount: string
}
export const createHoldingMandate = async (input: CreateHoldingMandateInput) => plutoMutation`
    createMandate(input: ${input}) ${mutationResponseString}
`

type UpdateHoldingMandateInput = {
    holding: string
    mandate: string
    depositAmount: number
    depositFrequency: string
    depositNextDate: string
    bankAccount: string
}
export const updateHoldingMandate = async (input: UpdateHoldingMandateInput) => plutoMutation`
    updateMandate(input: ${input}) ${mutationResponseString}
`

type CancelHoldingMandateInput = {
    holding: string
    mandate: string
}
export const cancelHoldingMandate = async (input: CancelHoldingMandateInput) => plutoMutation`
    cancelMandate(input: ${input}) ${mutationResponseString}
`

type CreateReimbursementRequestInput = {
    contactID: string
    accountID: string
    holdingID: string
    amount: number
    other?: {
        providerID: string
        physicianID: string
        dischargeDate: string
        availmentDate: string
        coverage: string
        contactEmail: string
    }
}
export const createReimbursementRequest = async (input: CreateReimbursementRequestInput, file: File) => {
    const query = `mutation($file: Upload!) {
        requestReimbursement(input: ${stringify(input)}, file: $file) ${mutationResponseString}
    }`
    return plutoFormMutation(query, { file })
}

type CreateUserAccountInput = {
    firstName: string
    middleNames?: string
    lastName: string
    suffix?: string
    dob: string
    phone: string
    email: string
    password: string
    address?: Address
    acceptedTerms: string[]
    dynamicInputs: Record<string, string | Record<string, string>>
}
export const createUserAccount = async (input: CreateUserAccountInput, dynamicFiles: File[]) =>
    plutoFormMutation(
        `
            mutation($dynamicFiles: [Upload!]!) {
                createConsumerAccount(input: ${stringify(input)}, dynamicFiles: $dynamicFiles) ${mutationResponseString}
            }
        `,
        { dynamicFiles }
    )
