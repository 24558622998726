/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { RecaptchaVerifier } from 'firebase/auth'
import * as Sentry from '@sentry/react'

type Recaptcha = RecaptchaVerifier
export default abstract class AuthInterface {
    abstract getIDToken(): Promise<string | null>
    abstract setIDToken(token: string): void
    abstract onChangeSubscriber(callback: (isLogin: boolean) => void): () => void

    abstract setTenant(tenant: 'consumer' | 'merchant'): void
    abstract setTenantByID(tenantID: string): void

    abstract createRecaptcha(): Recaptcha
    abstract clearRecaptcha(recaptcha: Recaptcha): void

    abstract sendVerificationEmail(redirect?: string): Promise<void>
    abstract sendPasswordResetEmail(email: string): Promise<void>
    abstract sendPhoneCode(phone: string, recaptcha?: Recaptcha): Promise<string>

    // eslint-disable-next-line class-methods-use-this
    async setMonitoringUser(data: { email?: string; id?: string; username?: string } | null): Promise<void> {
        Sentry.setUser(data)
    }

    abstract loginWithPhoneCode(code: string | number, validator: string, phone?: string): Promise<void>
    abstract loginWithEmailAndPassword(email: string, password: string): Promise<void>

    abstract resetPassword(newPassword: string): Promise<void>
    abstract verifyPasswordResetCode(code: string | number): Promise<void>
    abstract confirmPasswordReset(code: string | number, password: string): Promise<void>
    abstract verifyEmailCode(code: string | number): Promise<void>

    abstract logOut(): Promise<void>
}
