/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import {
    getAuth,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    multiFactor,
    getMultiFactorResolver,
    MultiFactorError,
    MultiFactorResolver,
} from 'firebase/auth'
import { getFirestore, doc, onSnapshot, collection, where, query, DocumentData, DocumentSnapshot, QuerySnapshot } from 'firebase/firestore'
import 'firebase/remote-config'

const app = initializeApp({
    apiKey: window.configuration['FIREBASE_API_KEY'],
    authDomain: window.configuration['FIREBASE_AUTH_DOMAIN'],
    databaseURL: window.configuration['FIREBASE_DATABASE_URL'],
    projectId: window.configuration['FIREBASE_PROJECT_ID'],
    appId: window.configuration['FIREBASE_APP_ID'],
    // storageBucket: window.configuration.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window.configuration['FIREBASE_MESSAGING_SENDER_ID'],
})

// ------------------------------------------------------- auth ----------------------------------------------------------------------

export const auth = getAuth(app)

export const getUserMFASession = async () => auth.currentUser && multiFactor(auth.currentUser).getSession()

export const getMFAAssertion = (codeCheckerID: string, code: string) =>
    PhoneMultiFactorGenerator.assertion(PhoneAuthProvider.credential(codeCheckerID, code))

export const enrollMFA = async (codeCheckerID: string, code: string) =>
    auth.currentUser && multiFactor(auth.currentUser).enroll(getMFAAssertion(codeCheckerID, code))

export const getMFResolver = (e: MultiFactorError) => getMultiFactorResolver(auth, e)

export const loginMFAWithPhoneCode = async (code: string, phoneCodeChecker: string, resolver: MultiFactorResolver) =>
    resolver.resolveSignIn(getMFAAssertion(phoneCodeChecker, code))

// ------------------------------------------------------- store ---------------------------------------------------------------------

export const db = getFirestore(app)

export const billCodeListener = (handleChange: (d: DocumentSnapshot<DocumentData>) => void, code: string) =>
    onSnapshot(doc(db, 'vcodes', code), handleChange)

export const pendingBillListener = (handleChange: (d: QuerySnapshot<DocumentData>) => void, contactID: string) =>
    onSnapshot(query(collection(db, 'bills'), where('recipient', '==', contactID), where('status', '==', 'pending')), handleChange)

export const merchantBillListener = (handleChange: (d: QuerySnapshot<DocumentData>) => void, contactID: string) =>
    onSnapshot(query(collection(db, 'bills'), where('requester', '==', contactID)), handleChange)

// ------------------------------------------------------- messaging -----------------------------------------------------------------

export const errorlessMessaging = async () => {
    const isSupp = await isSupported()
    if (isSupp) return getMessaging(app)
    console.debug('Messaging not supported on device')
    return null
}

export const messagingKey = window.configuration['VAPID_KEY']
export const getMessagingToken = async () => {
    try {
        const messaging = await errorlessMessaging()
        if (!messaging) return null
        if (!messagingKey) {
            console.debug('No notification key configured, skipping notification initialization')
            return null
        }
        const token = await getToken(messaging, { vapidKey: messagingKey })
        return token
    } catch (e) {
        console.debug(e)
        return null
    }
}
