/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { useEffect } from 'react'

let timeout

const useInterval = (func, interval = 1000) => {
    const updateInterval = () => {
        func()
        timeout = setTimeout(updateInterval, interval)
    }

    useEffect(() => {
        updateInterval()
        return () => clearTimeout(timeout)
    }, [])
}

export default useInterval
