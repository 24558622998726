/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode } from 'react'
import styled from 'styled-components'
import { Text } from 'vspry-style-components'

import LanguageSelector from 'components/LanguageSelector'

const Core = styled.div`
    background-color: ${(props) => props.theme.secondaryBG};
    color: ${(props) => props.theme.text};
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const Content = styled.div`
    margin: auto;
    width: 100%;
    max-width: 460px;
    padding: 0px 20px 40px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Inner = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
`

const Footer = styled.div`
    padding-bottom: 5px;
`

type PublicPageContainerProps = {
    children: ReactNode
}
export default function PublicPageContainer({ children }: PublicPageContainerProps) {
    return (
        <Core>
            <Content>
                <Inner>{children}</Inner>
            </Content>
            <Footer>
                <LanguageSelector />
                <Text size='xSmall' margin='no' $light>
                    {' '}
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <a href='https://app-change-log.vspry.com/' target='_blank' rel='noreferrer'>
                        {window.configuration['APP_VERSION']}
                    </a>{' '}
                    {/* eslint-disable-next-line i18next/no-literal-string */}©{' '}
                    {window.configuration['APP_FOOTER'] || 'VSPRY INTERNATIONAL LIMITED 2019-2023'}
                </Text>
            </Footer>
        </Core>
    )
}
