/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import axios, { AxiosError, Method } from 'axios'
import { ErrorSwal, InfoSwal } from 'vspry-style-components'
import * as Sentry from '@sentry/react'
import { translateContextless } from 'context/localeContext'
import { MutationResponse } from './types'

const serverEndpoint = `${window.configuration['PLUTO_URL']}/graphql`
const daisyEndpoint = `${window.configuration['VENUS_URL']}/graphql`

export class GQLError extends Error {
    constructor(type: string, name: string, errors: string[]) {
        super(`${type} ${name} error:\n\t${errors.join('\n\t')}\n`)
        // eslint-disable-next-line i18next/no-literal-string
        this.name = 'GQLError'
    }
}

export const MaintenanceSwal = () =>
    InfoSwal.fire({
        // eslint-disable-next-line i18next/no-literal-string
        icon: 'warning',
        title: translateContextless('swal.maintenance.title'),
        text: translateContextless('swal.maintenance.text'),
        showConfirmButton: false,
        allowOutsideClick: false,
    })

export const RateLimitSwal = () =>
    InfoSwal.fire({
        // eslint-disable-next-line i18next/no-literal-string
        icon: 'warning',
        title: translateContextless('swal.rateLimit.title'),
        text: translateContextless('swal.rateLimit.text'),
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2500,
    })

// rest interface
export const restQuery = async (
    method: Method,
    url: string,
    data: { query: string } | FormData,
    headers: Record<string, string> | undefined = undefined,
    quiet = false
) => {
    // creating auth token
    const token = window.auth ? await window.auth.getIDToken() : ''

    try {
        const res = await axios({
            method,
            url,
            headers: { Authorization: `Bearer ${token}`, ...headers },
            data,
        })
        return res ? res.data : null
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e.response?.status === 503) {
                MaintenanceSwal()
                return null
            }
            if (e.response?.status === 429) {
                RateLimitSwal()
                return null
            }
            Sentry.captureException(
                !(data instanceof FormData)
                    ? new GQLError(
                          data.query.split(' { ')[0],
                          data.query.split(' { ')[1].split('(')[0].split(' }')[0],
                          e.response?.data?.errors?.map((er: Error) => er.message) ?? [e.message]
                      )
                    : e,
                { extra: { response: e.response, request: e.request } }
            )
        } else Sentry.captureException(e, { extra: { method, url, data, headers } })
        console.error(e)
        if (!quiet) ErrorSwal.fire({ title: translateContextless('swal.error.title'), text: translateContextless('swal.error.unexpected') })
        return null
    }
}

// formatting gql queries
const formatQuery = (q: string) => {
    let formatted = q.replace(/\n/g, '')
    while (formatted.includes('  ')) formatted = formatted.replace(/ {2}/g, ' ')
    return formatted
}

//  standard graphql operation
export const graphql = async <T>(query: string, dest: 'pluto' | 'daisy' = 'pluto', quiet = false): Promise<T | null> => {
    // replacing formatting characters for more readable queries in the request
    const data = { query: formatQuery(query) }
    const queryName = formatQuery(query).split('{')[1].split('(')[0].split('}')[0].trim()

    const res =
        dest === 'daisy'
            ? !!window.configuration['VENUS_URL'] && (await restQuery('POST', daisyEndpoint, data, undefined, quiet))
            : await restQuery('POST', serverEndpoint, data, undefined, quiet)
    if (res?.data?.[queryName]) return res.data[queryName]
    return null
}

// the val parameter denotes if it is the value of a key in an object
export const stringify = (obj: unknown, val = false): string => {
    // eslint-disable-next-line i18next/no-literal-string
    if (obj === null || obj === undefined) return 'null'
    if (typeof obj === 'string') return val ? `"${obj}"` : obj
    if (Array.isArray(obj)) {
        if (obj.length === 0) return '[]'
        return obj.reduce((previous, current, index) => `${previous}${stringify(current)}${index === obj.length - 1 ? ']' : ','}`, '[')
    }
    if (typeof obj !== 'object' || obj === null) return JSON.stringify(obj)

    const props: string = (Object.keys(obj) as (keyof typeof obj)[]).map((k) => `${k}:${stringify(obj[k], true)}`).join(',')
    return `{${props}}`
}

// gql templates
export const buildTemplate = (strings: TemplateStringsArray, args: unknown[]) =>
    strings.reduce((total, s, i) => `${total}${s}${args[i] ? stringify(args[i]) : ''}`, '')

// gql form operations
// see: https://github.com/jaydenseric/graphql-multipart-request-spec for more info
const formMutation = async <T>(
    query: string,
    fields: Record<string, string | Blob | (Blob | string)[]>,
    dest: 'pluto' | 'daisy' = 'pluto',
    quiet = false
): Promise<T | null> => {
    // eslint-disable-next-line i18next/no-literal-string
    const headers = { 'Content-Type': 'multipart/form-data' }
    const form = new FormData()
    const variables: Record<string, null | null[]> = {}
    const map: string[][] = []

    Object.keys(fields).forEach((k) => {
        const field = fields[k]
        if (!Array.isArray(field)) {
            variables[k] = null
            map.push([`variables.${k}`])
            form.append(String(map.length - 1), field ?? String(field))
        } else {
            const newVar: null[] = []
            field.forEach((v, i) => {
                newVar.push(null)
                map.push([`variables.${k}.${i}`])
                form.append(String(map.length - 1), v ?? String(v))
            })
            variables[k] = newVar
        }
    })

    const formMap = map.reduce((o, field, i) => ({ ...o, [i]: field }), {})
    form.append('operations', JSON.stringify({ query: formatQuery(query), variables }))
    form.append('map', JSON.stringify(formMap))

    const queryName = formatQuery(query).split(' { ')[1].split('(')[0]

    const res = await restQuery('POST', dest === 'daisy' ? daisyEndpoint : serverEndpoint, form, headers, quiet)
    if (res && res.data) return res.data[queryName] || res.data
    return null
}

// gql query template helper, usage e.g. graphQuery`user { info { firstName } }`
export const plutoQuery = async <T = unknown>(strings: TemplateStringsArray, ...args: unknown[]) =>
    graphql<T>(`query { ${buildTemplate(strings, args)} }`)

export const plutoQueryQuiet = async <T = unknown>(strings: TemplateStringsArray, ...args: unknown[]) =>
    graphql<T>(`query { ${buildTemplate(strings, args)} }`, 'pluto', true)

// gql mutation template helper, usage e.g. graphMutation`addDevice(input: ${input}) { devices }`
export const plutoMutation = async <T = MutationResponse>(strings: TemplateStringsArray, ...args: unknown[]) =>
    graphql<T>(`mutation { ${buildTemplate(strings, args)} }`)

export const plutoFormMutation = async <T = MutationResponse>(
    query: Parameters<typeof formMutation>[0],
    fields: Parameters<typeof formMutation>[1]
) => formMutation<T>(query, fields)

// gql query template helper, usage e.g. graphQuery`user { info { firstName } }`
export const daisyQuery = async <T = unknown>(strings: TemplateStringsArray, ...args: unknown[]) =>
    graphql<T>(`query { ${buildTemplate(strings, args)} }`, 'daisy')

// gql mutation template helper, usage e.g. graphMutation`addDevice(input: ${input}) { devices }`
export const daisyMutation = async <T = MutationResponse>(strings: TemplateStringsArray, ...args: unknown[]) =>
    graphql<T>(`mutation { ${buildTemplate(strings, args)} }`, 'daisy')

export const daisyFormMutation = async <T = MutationResponse>(
    query: Parameters<typeof formMutation>[0],
    fields: Parameters<typeof formMutation>[1]
) => formMutation<T>(query, fields, 'daisy')
